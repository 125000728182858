<template>
	<main>
		<h1>Courses</h1>
		<ResponsiveTable :headers="['Name', '']" :rows="courses" />

		<BaseButton type="submit" :href="{name: 'AdminCoursesCreate'}">
			Create course
		</BaseButton>
	</main>
</template>

<script>
	import Backend from '../../inc/backend';
	import BaseButton from '../../components/BaseButton';
	import ResponsiveTable from '../../components/ResponsiveTable';
	import {roleGuard} from '../../inc/auth';

	export default {
		...roleGuard('Courses', 'any', 'teacher'),
		components: {
			BaseButton,
			ResponsiveTable
		},
		data() {
			return {
				courses: []
			};
		},
		created() {
			Backend.get('courses?admin=1').then(res => {
				this.courses = res.data.map(course => [
					{label: course.name},
					[
						{label: 'Submissions', href: {name: 'AdminSubmissions', query: {courseId: course.id}}},
						{label: 'View', href: {name: 'Course', params: {courseId: course.id}}}
					]
				]);
			});
		}
	};
</script>